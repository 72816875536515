import * as CommonConstants from './../constants/common.js'

export const setColorForSession = (headerMode) => {
    if (headerMode === "light") {
        window.localStorage.setItem('encryptAnimationCSS', CommonConstants.encryptAnimationCSS.light);
        window.localStorage.setItem('colorModeState-headerMode', JSON.stringify(CommonConstants.colorModeState.lightModeState.headerMode));
        window.localStorage.setItem('cssState-appBackground', JSON.stringify(CommonConstants.cssState.lightCssState.appBackground));
        window.localStorage.setItem('cssState-appHeader', JSON.stringify(CommonConstants.cssState.lightCssState.appHeader));
        window.localStorage.setItem('bgImage', CommonConstants.bgImage.day);
    } else {
        window.localStorage.setItem('encryptAnimationCSS', CommonConstants.encryptAnimationCSS.dark);
        window.localStorage.setItem('colorModeState-headerMode', JSON.stringify(CommonConstants.colorModeState.darkModeState.headerMode));
        window.localStorage.setItem('cssState-appBackground', JSON.stringify(CommonConstants.cssState.darkCssState.appBackground));
        window.localStorage.setItem('cssState-appHeader', JSON.stringify(CommonConstants.cssState.darkCssState.appHeader));
        window.localStorage.setItem('bgImage', CommonConstants.bgImage.night);
    }
}