import './../App.css';

import Navbar from 'react-bootstrap/Navbar'
/* import Container from 'react-bootstrap/Container' */
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

function AppNavBar() {

    return <>
        <Navbar className="py-4 navbar" collapseOnSelect expand="lg" scrolling dark fixed="top">
            <Navbar.Brand href="/">Atelci</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">  
              <Nav className="justify-content-end">
                <NavDropdown title="Some Cool Stuff" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="component-creator">Component Creator</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#" disabled>Stay Tuned..</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="about">About Me</Nav.Link>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
    </>;
}

export default AppNavBar;