import 'animate.css';

import './../App.css';

import React, { useState, useEffect } from 'react'
import {
    useLocation
  } from "react-router-dom"

function ContentBox(props) {

    return <>
        <div className={props.side + " animate__animated " + props.animation}>
            <h1>{props.title}</h1>
        </div>
    </>;
}

export default ContentBox;