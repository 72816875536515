import './../App.css';

import React, { useEffect } from 'react';

import { useDencrypt } from "use-dencrypt-effect";

const values = ["Hello 👋", "I'm Ataman", "Software Engineer 👨‍💻", "Living in İstanbul"];

const EncryptAnimation = (props) => {
  const { result, dencrypt } = useDencrypt();

  useEffect(() => {
    let i = 0;

    const action = setInterval(() => {
      dencrypt(values[i]);

      i = i === values.length - 1 ? 0 : i + 1;
    }, 4000);

    return () => clearInterval(action);
  }, [dencrypt]);

  return <div className={props.css}>{result}</div>;
};

export default EncryptAnimation;