import NYCNight from './../nyc-night.jpg';
import NYCDay from './../nyc-day.jpg';

export const colorModeState = {
    darkModeState: {
        headerMode: "dark"
    },
    lightModeState: {
        headerMode: "light"
    }
}
export const cssState = {
    darkCssState: {
        appBackground: "App-background",
        appHeader: "App-header"
    },
    lightCssState: {
        appBackground: "App-background-light",
        appHeader: "App-header-light"
      }
}
export const bgImage = {
    night: NYCNight,
    day: NYCDay
}
export const encryptAnimationCSS = {
    dark: "body-center-text-dark",
    light: "body-center-text-light"
}
