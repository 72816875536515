// Libraries
import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route 
} from "react-router-dom"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import DarkModeToggle from "react-dark-mode-toggle"

// CSS and images
import './App.css';

// Components
import AppNavBar from './components/navbar.js'
import EncryptAnimation from './components/encryptanimation.js'
import ContentBox from './components/contentbox.js';

// Constants and utilities
import * as CommonConstants from './constants/common.js'
import * as Helper from './utilities/helper.js'


function App() {

  // Dark Mode states
  const [colorMode, setColorMode] = useState({
    // Header
    headerMode: JSON.parse(window.localStorage.getItem('colorModeState-headerMode')) || CommonConstants.colorModeState.darkModeState.headerMode
  });

  // Main CSS states
  const [cssStyleClass, cssStyleClassChange] = useState({
    // Body
    appBackground: JSON.parse(window.localStorage.getItem('cssState-appBackground')) || CommonConstants.cssState.darkCssState.appBackground,
    appHeader: JSON.parse(window.localStorage.getItem('cssState-appHeader')) || CommonConstants.cssState.darkCssState.appHeader
  });

  // Background image
  const [bgImage, changeBgImage] = useState(window.localStorage.getItem('bgImage') || CommonConstants.bgImage.night)

  // Animation
  const [encryptAnimationCSS, changeEncryptAnimationCSS] = useState(window.localStorage.getItem('encryptAnimationCSS') || CommonConstants.encryptAnimationCSS.dark)

  // Color mode toggle button
  const changeColorMode = () => {
    if (colorMode.headerMode === "dark") {
      // change it to light and keep color mode on sessions
      Helper.setColorForSession(CommonConstants.colorModeState.lightModeState.headerMode)
      setColorMode(CommonConstants.colorModeState.lightModeState);
      cssStyleClassChange(CommonConstants.cssState.lightCssState);
      changeBgImage(CommonConstants.bgImage.day);
      changeEncryptAnimationCSS(CommonConstants.encryptAnimationCSS.light);
    } else {
      // change it to dark
      Helper.setColorForSession(CommonConstants.colorModeState.darkModeState.headerMode)
      setColorMode(CommonConstants.colorModeState.darkModeState);
      cssStyleClassChange(CommonConstants.cssState.darkCssState);
      changeBgImage(CommonConstants.bgImage.night);
      changeEncryptAnimationCSS(CommonConstants.encryptAnimationCSS.dark);
    }
  }

  return (
      <Router>

        <header>
          <AppNavBar mode={colorMode}></AppNavBar>
          <img src={bgImage} className="bg-image vh-100 w-100" alt="nyc"/>
          <DarkModeToggle
            className="body-top-right"
            onChange={changeColorMode}
            checked={colorMode.headerMode === "dark" ? true : false}
            size="60px"
          >
          </DarkModeToggle>
        </header>

        <Switch>
            <Route exact path="/">
              <div className={cssStyleClass.appBackground}>
                <EncryptAnimation css={encryptAnimationCSS}></EncryptAnimation>
              </div>
            </Route>
            <Route path="/component-creator">
              <h1>404 Not Found</h1>
            </Route>
            <Route path="/about">
              
              <ContentBox side="contentbox-left" animation="animate__bounceInLeft" title="About Me"></ContentBox>

              <ContentBox side="contentbox-right" animation="animate__bounceInRight" title="Contact Me"></ContentBox>

            </Route>
          </Switch>
      </Router>
  );
}

export default App;
